import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Header = () => {
  const [menuIsToggled, setMenuIsToggled] = useState(false);
  const toggleMenu = () => setMenuIsToggled(!menuIsToggled);

  return (
    <nav
      className="navbar is-light"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div
          role="button"
          href="#"
          className={`navbar-burger burger ${menuIsToggled ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          onClick={toggleMenu}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>
      </div>
      <div
        id="navbar"
        className={`navbar-menu ${menuIsToggled ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          <Link className="navbar-item" to="/">
            <span>Home</span>
          </Link>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
